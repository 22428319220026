import AmBankLogo from '../assets/images/ambank_logo.jpg';
import HLBLogo from '../assets/images/hlb_logo.png';
import RHBLogo from '../assets/images/rhb_logo.jpg';
import PublicBankLogo from '../assets/images/public_bank_logo.png';

export const BANK_RESELLER_INFO: Record<
  string,
  {
    logo: string;
    logoAlt: string;
    title: string;
  }
> = {
  '27': {
    logo: HLBLogo,
    logoAlt: 'Hong Leong Bank Logo',
    title: 'Hong Leong Bank Special Partnership Programme',
  },
  '32': {
    logo: RHBLogo,
    logoAlt: 'RHB Bank Logo',
    title: 'RHB Special Partnership Programme',
  },
  '48': {
    logo: AmBankLogo,
    logoAlt: 'AmBank Logo',
    title: 'AmBank SME BizMate - Kakitangan.com Partnership',
  },
  '61': {
    logo: PublicBankLogo,
    logoAlt: 'Public Bank Logo',
    title: 'PB Enterprise Digital SME Assist Programme',
  },
};

export const COMPANY_SIZE_OPTIONS = [
  { value: '0', label: 'Select' },
  { value: '3', label: '1-3' },
  { value: '5', label: '4-5' },
  { value: '10', label: '6-10' },
  { value: '15', label: '11-20' },
  { value: '25', label: '21-30' },
  { value: '35', label: '31-40' },
  { value: '45', label: '41-50' },
  { value: '55', label: '51-60' },
  { value: '65', label: '61-70' },
  { value: '75', label: '71-80' },
  { value: '85', label: '81-90' },
  { value: '95', label: '91-100' },
  { value: '150', label: '101-200' },
  { value: '250', label: '200+' },
];

export const COMPANY_LOCATION_OPTIONS = [
  {
    group: 'Malaysia',
    items: [
      'Kuala Lumpur',
      'Selangor',
      'Johor',
      'Kedah',
      'Kelantan',
      'Labuan',
      'Malacca',
      'Negeri Sembilan',
      'Pahang',
      'Penang',
      'Perak',
      'Perlis',
      'Putrajaya',
      'Sabah',
      'Sarawak',
      'Terengganu',
    ],
  },
  {
    group: 'Rest of the World',
    items: [
      'Afghanistan',
      'Albania',
      'Algeria',
      'Andorra',
      'Angola',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bhutan',
      'Bolivia',
      'Bosnia and Herzegovina',
      'Botswana',
      'Brazil',
      'Brunei',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      "Côte d'Ivoire",
      'Cabo Verde',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Central African Republic',
      'Chad',
      'Chile',
      'China',
      'Colombia',
      'Comoros',
      'Congo (Congo-Brazzaville)',
      'Costa Rica',
      'Croatia',
      'Cuba',
      'Cyprus',
      'Czechia (Czech Republic)',
      'Democratic Republic of the Congo (Congo-Kinshasa)',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      "Eswatini (fmr. 'Swaziland')",
      'Ethiopia',
      'Fiji',
      'Finland',
      'France',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Greece',
      'Grenada',
      'Guatemala',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Holy See',
      'Honduras',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran',
      'Iraq',
      'Ireland',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Kuwait',
      'Kyrgyzstan',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Madagascar',
      'Malawi',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Mauritania',
      'Mauritius',
      'Mexico',
      'Micronesia',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Morocco',
      'Mozambique',
      'Myanmar (formerly Burma)',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'North Korea',
      'North Macedonia (formerly Macedonia)',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Palestine State',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Poland',
      'Portugal',
      'Qatar',
      'Romania',
      'Russia',
      'Rwanda',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Vincent and the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Korea',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Sweden',
      'Switzerland',
      'Syria',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Timor-Leste',
      'Togo',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'United States of America',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Venezuela',
      'Vietnam',
      'Yemen',
      'Zambia',
      'Zimbabwe',
    ],
  },
];

export const PASSWORD_REQUIREMENTS = {
  minLength: 8,
  needsLowerCase: true,
  needsNumber: true,
  needsUpperCase: true,
  needsSymbol: false,
};

export const COMPANY_INDUSTRIES = [
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Advertising, Arts & Media', label: 'Advertising, Arts & Media' },
  {
    value: 'Agriculture, forestry and fishery',
    label: 'Agriculture, forestry and fishery',
  },
  {
    value: 'Architecture / Construction / Engineering',
    label: 'Architecture / Construction / Engineering',
  },
  { value: 'Automotive', label: 'Automotive' },
  {
    value: 'Banking / Financial / Insurance services',
    label: 'Banking / Financial / Insurance services',
  },
  {
    value: 'Clinic / Health Care / Medical',
    label: 'Clinic / Health Care / Medical',
  },
  { value: 'Ecommerce', label: 'Ecommerce' },
  { value: 'Education & Training', label: 'Education & Training' },
  { value: 'Energy & Utilities', label: 'Energy & Utilities' },
  { value: 'Government & GLC', label: 'Government & GLC' },
  { value: 'Hospitality & Tourism', label: 'Hospitality & Tourism' },
  {
    value: 'Information & Communication Technology',
    label: 'Information & Communication Technology',
  },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'NGO', label: 'NGO' },
  { value: 'Others', label: 'Others' },
  {
    value: 'Professional & Business Services',
    label: 'Professional & Business Services',
  },
  { value: 'Real Estate & Property', label: 'Real Estate & Property' },
  { value: 'Retail & Consumer Products', label: 'Retail & Consumer Products' },
  {
    value: 'Trading (imports and exports)',
    label: 'Trading (imports and exports)',
  },
  { value: 'Transportation & Logistics', label: 'Transportation & Logistics' },
];
